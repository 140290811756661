<template>
	<div>
		<base-header type="gradient-success" class="pb-8 pt-5 pt-md-8"></base-header>

		<div class="container-fluid mt--7" id="cataloge">
			<div class="row">
				<div class="col">
					<div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
						<!-- <div class="row">
                            <base-button type="primary"  @click="exportImport(1)" size="sm" class="margin ml-4">Import</base-button>
                            <download-excel :data="reportData" :name="filename">
                                <base-button  type="primary" size="sm" class="margin ml-3" >Export</base-button>
                            </download-excel>
						</div>-->
						<div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
							<div class="row align-items-left">
								<div class="col">
									<h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">Products</h3>
								</div>
								<div class="col text-right"></div>
							</div>
							<div v-if="exportModel== true">
								<div class="modal import" style="display:block; width:100%">
									<div class="modal-dialog">
										<div class="modal-content ml-279 t-160 import" style="width:390px; max-height:600px; ">
											<div class="modal-header">
												<h3 class="modal-title">Import file</h3>
												<button
													type="button"
													class="close"
													data-dismiss="modal"
													aria-label="Close"
													@click="closeModel()"
												>
													<span aria-hidden="true">&times;</span>
												</button>
											</div>

											<div class="modal-body import-body">
												<vue-csv-import v-model="csv" url="/hello" headers="null" :map-fields="['name', 'age']">
													<template slot="error">
														<p style="color:red">File type is invalid</p>
													</template>
													<template slot="next">
														<base-button
															type="primary"
															style="float:right"
															@click="updateQuantity(item)"
															size="sm"
														>Import</base-button>
													</template>
												</vue-csv-import>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="product_loader" v-show="loader == true">
							<img src="/img/loader.gif" class="loader" />
						</div>
						<div v-if="openModel" id="model">
							<div class="modal" style="display:block; width:100%">
								<div class="modal-dialog" style="width:100%">
									<div class="modal-content t-100" style="max-height:600px;overflow-y:auto; width:100%">
										<div class="modal-header">
											<a
												class="pl-2 pt-1 external-link-alt"
												style="display:inline-block"
												target="_blank"
												:href="url"
											>
												<h5 class="modal-title pointer">View Product</h5>
											</a>
											<a
												class="pl-2 external-link-alt"
												style="display:inline-block"
												target="_blank"
												:href="url"
											>
												<i class="fa fa-eye m-0"></i>
											</a>

											<button
												type="button"
												class="close"
												data-dismiss="modal"
												aria-label="Close"
												@click="closeModel()"
											>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body">
											<div class="table-responsive">
												<table class="table tablesorter" :class="tableClass">
													<thead :class="theadClasses">
														<tr>
															<!-- <th>
                                                            <label class="form-checkbox">
                                                                <input type="checkbox" v-model="selectAll" @click="select">
                                                                <i class="form-icon"></i>
                                                            </label>
															</th>-->
															<th v-for="item in subHeaders" :key="item.id">
																<b>{{item.name}}</b>
															</th>
														</tr>
													</thead>
													<tbody :class="tbodyClasses">
														<tr v-for="(item, index) in subArray" :key="item.id">
															<!-- <td>
                                                            <label class="form-checkbox margin">
                                                                <input type="checkbox" :value="item.id_product" v-model="selected">
                                                                <i class="form-icon"></i>
                                                            </label>
															</td>-->
															<td>
																<span class="margin" style="display:inline-block">{{item.id_product}}</span>
															</td>
															<td class="pl-0 alignment pr-0">
																<img
																	:src="`https://images.wndw.in/live/inside/100x100/${ item.image}`"
																	width="45px"
																	height="auto"
																/>
																<b>{{item.name}}</b>
																<!-- <a class="margin pl-2 external-link-alt" style="display:inline-block" target="_blank" :href=item.url_key><i class="fas fa-external-link-alt m-0"></i></a></td> -->
															</td>
															<td>
																<span class="margin" style="display:inline-block">{{item.sku}}</span>
															</td>
															<td>
																<span class="margin" style="display:inline-block">{{item.size}}</span>
															</td>

															<td>
																<input
																	type="text"
																	v-model="item.price"
																	@input="Quantity(item)"
																	class="m_align input-class mt-10"
																/>
															</td>
															<td>
																<input
																	type="text"
																	@input="Quantity(item)"
																	v-model="item.selling_price"
																	class="m_align input-class mt-10"
																/>
															</td>
															<td>
																<div class="qty_box mt-2">
																	<a @click="Quantity(item,index,0)">
																		<i class="fa fa-minus"></i>
																	</a>
																	<input type="text" v-model="item.quantity" @click="qtyChange(item, index)" />
																	<a @click="Quantity(item,index,1)">
																		<i class="fa fa-plus"></i>
																	</a>
																</div>
															</td>
															<td>
																<span span class="margin" style="display:inline-block">{{item.updated_at}}</span>
															</td>

															<td>
																<base-button
																	type="primary"
																	:disabled="item.isDisable"
																	@click="updateQuantity(item)"
																	size="sm"
																	class="margin"
																>Update</base-button>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<table class="table tablesorter" :class="tableClass">
								<thead :class="theadClasses">
									<tr>
										<th v-for="item in headers" :key="item.id">
											<b>{{item.name}}</b>
										</th>
									</tr>
								</thead>
								<tbody :class="tbodyClasses">
									<tr v-for="(item) in data" :key="item.id">
                    <td>
											<span class="alignment" style="display:inline-block; ">{{item.id_product}}</span>
										</td>
                    <td v-if="isVendor && item.store_data != null && item.store_data.name != null">
                      <span class="alignment" style="display:inline-block; ">{{item.store_data.name}}</span>
                    </td>
                    <td v-if="item.store_data == null || item.store_data.name == null"></td>
                    <td class="pl-0 pr-0">
											<img
												:src="`https://images.wndw.in/live/inside/100x100/${ item.image}`"
												width="45px"
												height="auto"
											/>
											<a class="ml-3 pointer" style="display:inline-block; " @click="open(item)">{{item.name}}</a>
											<a
												class="pl-2 external-link-alt"
												style="display:inline-block"
												target="_blank"
												:href="item.url_key"
											>
												<i class="fas fa-external-link-alt m-0"></i>
											</a>
										</td>
										<td class="p27">{{item.stock_status}}</td>
										<td class="p27">{{calInventory(item)}}</td>
										<td class="p27" v-if="item.categories.length>0">{{item.categories[0].name}}</td>
										<td v-else></td>
									</tr>
								</tbody>
							</table>
						</div>

						<div
							class="card-footer d-flex justify-content-end"
							:class="type === 'dark' ? 'bg-transparent': ''"
						>
							<!-- <base-pagination total=120 store='product' @clicked="onClickChild"></base-pagination> -->
							<template v-if="data.length>0">
								<ul
									class="pagination"
									:class="[size && `pagination-${size}`, align && `justify-content-${align}`]"
								>
									<li class="page-item prev-page pointer" :class="{disabled: value === 1}">
										<a class="page-link" aria-label="Previous" @click="prevPage">
											<span aria-hidden="true">
												<i class="fa fa-angle-left" aria-hidden="true"></i>
											</span>
										</a>
									</li>
									<li
										class="page-item pointer"
										:class="{active: value === item}"
										:key="item"
										v-for="item in range(minPage, maxPage)"
									>
										<a class="page-link" @click="changePage(item)">{{item}}</a>
									</li>
									<li class="page-item next-page pointer" :class="{disabled: value === totalPages}">
										<a class="page-link" aria-label="Next" @click="nextPage">
											<span aria-hidden="true">
												<i class="fa fa-angle-right" aria-hidden="true"></i>
											</span>
										</a>
									</li>
								</ul>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { VueCsvImport } from "vue-csv-import";
	import axios from "axios";
	import * as API from "../API";
	export default {
		name: "tables",
		components: { VueCsvImport },
		data() {
			return {
        isVendor: false,
				try: 1,
				openModel: false,
				exportModel: false,
				subArray: [],
				isDisable: false,
				filename: "",
				reportData: [],
				type: "",
				item: {
					product_id: "undefined"
				},
				loader: true,
				data: [],
				qty: 1,
				defaultPagesToDisplay: 3,
				selected: [],
				selectAll: false,
				headers: [
					{ name: "Product", value: "discount" },
					//  { name: 'Details',value:'details'},
					{ name: "Status", value: "status" },
					//  { name:'Selling P',value:'store'},
					{ name: "Inventory", value: "qty" },
					{ name: "Type" }
				],
				subHeaders: [
					{ name: "ID", value: "discount" },
					{ name: "Product", value: "discount" },
					{ name: "Sku", value: "discount" },
					{ name: "Size", value: "discount" },
					{ name: "Retail p", value: "discount" },
					{ name: "Selling p", value: "discount" },
					{ name: "Inventory", value: "qty" },
					{ name: "Last Updated" },

					{ name: "Action" }
				]
			};
		},
		props: {
			theadClasses: {
				type: String,
				default: "",
				description: "<thead> css classes"
			},
			tbodyClasses: {
				type: String,
				default: "",
				description: "<tbody> css classes"
			},
			pageCount: {
				type: Number,
				default: 0,
				description:
					"Pagination page count. This should be specified in combination with perPage"
			},
			perPage: {
				type: Number,
				default: 10,
				description:
					"Pagination per page. Should be specified with total or pageCount"
			},
			total: {
				type: Number,
				default: 0,
				description:
					"Can be specified instead of pageCount. The page count in this case will be total/perPage"
			},
			value: {
				type: Number,
				default: 1,
				description: "Pagination value"
			},
			size: {
				type: String,
				default: "",
				description: "Pagination size"
			},
			align: {
				type: String,
				default: "",
				description: "Pagination alignment (e.g center|start|end)"
			}
		},
		computed: {
			tableClass() {
				return this.type && `table-${this.type}`;
			},
			totalPages() {
				console.log("pageCount", this.pageCount);
				if (this.pageCount > 0) return this.pageCount;
				if (this.total > 0) {
					return Math.ceil(this.total / this.perPage);
				}
				return 1;
			},
			pagesToDisplay() {
				if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
					return this.totalPages;
				}
				return this.defaultPagesToDisplay;
			},
			minPage() {
				if (this.value >= this.pagesToDisplay) {
					const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
					const newMaxPage = pagesToAdd + this.value;
					if (newMaxPage > this.totalPages) 
					{
					return this.totalPages - this.pagesToDisplay + 1;
					}
					return this.value - pagesToAdd;
				} else {
					return 1;
				}
			},
			maxPage() {
				if (this.value >= this.pagesToDisplay) {
					const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
					const newMaxPage = pagesToAdd + this.value;
					if (newMaxPage < this.totalPages) {
						return newMaxPage;
					} else {
						return this.totalPages;
					}
				} else {
					return this.pagesToDisplay;
				}
			}
		},
		watch: {
			perPage() {
				this.$emit("input", 1);
			},
			total() {
				this.$emit("input", 1);
			}
		},
		created() {
      if(localStorage.getItem("role") == "vendor"){
        this.isVendor = true;
        this.headers.unshift({ name: "Store", value: "store" })
      }
      this.headers.unshift(      { name: "ID", value: "discount" })

      // this.data= this.$store.state.Catalouge
			// console.log("this.catalouge in",this.data)
		},
		async mounted() {
			await this.$store.dispatch("getCatalouge", 1);
			this.total = this.$store.state.totalProduct;
			this.data = this.$store.state.Catalouge;

			console.log("thiswq wq  ", this.data);
			this.data.forEach(e => (e.isDisable = true));
			this.loader = false;
		},
		methods: {
			select() {
				this.selected = [];
				if (!this.selectAll) {
					for (let i in this.data) {
						console.log(i);
						this.selected.push(this.data[i].id_product);
					}
				}
			},
			open(item) {
				console.log("item", item.product_varients);
				console.log("item", item);
				this.subArray = item.product_varients;
				console.log("subArray", this.subArray);
				this.subArray.forEach(e => (e.isDisable = true));
				this.url = item.url_key;
				// this.subArray.forEach(e=>e.url_key = item.url_key)

				this.openModel = true;
				document.querySelector("body").style.overflow = "hidden";
				document.querySelector("#model").style.overflow = "auto";
			},
			closeModel() {
				this.openModel = false;
				this.subArray = [];
				this.exportModel = false;
				document.querySelector("body").style.overflow = "auto";
			},
			calInventory(item) {
				let qty = 0;
				item.product_varients.map(e => {
					qty = qty + e.quantity;
					return qty;
				});
				return qty;
			},
			onClickChild(value) {
				this.data = value;
			},
			async updateQuantity(item) {
				let qty = "";
				qty = item.quantity;
				let index = this.subArray.findIndex(e => e.id_product == item.id_product);
				this.subArray[index].quantity = qty;
				let params = {
					quantity: qty,
					product_id: item.id_product,
					price: item.price,
					selling_price: item.selling_price
				};
				// let resp = await this.$store.dispatch('updateQty',params)
				let token = window.$cookies.get("accessToken");
				try {
          console.log("token", token);
					const response = await axios.post(
						`${API.baseURL}/vendor/update-product-quantity`,
						params,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                },
                withCredentials: true
              }
					);
					this.$Msg.success("Product updated successfully", {
						position: "top-right",
						timeout: 3000
					});
					console.log(response);
				} catch (error) {
					// return error.errors
					this.$Msg.error(Object.values(error.response.data.errors)[0][0], {
						position: "top-right",
						timeout: 3000
					});
				}
				//     console.log("called",resp);
				//     if(
				//         resp=="The given data was invalid."
				//     ){
				//          this.$Msg.error(resp, {
				//     position: "top-center",
				//     timeout: 3000,
				// });

				//     }else{
				//              this.$Msg.success('Product updated successfully', { position: 'top-right' ,timeout: 3000 });
				//         // console.log(response)
				//     }

				var res = this.subArray.map(e => {
					e.isDisable = true;
					return e;
				});
				this.subArray = res;
			},
			async Quantity(item, index, type) {
				this.item = item;
				if (type == 0) {
					this.subArray[index].quantity = this.subArray[index].quantity - 1;
				}
				if (type == 1) {
					this.subArray[index].quantity = this.subArray[index].quantity + 1;
				}
				var res = this.subArray.map(e => {
					if (e.id_product == this.item.id_product) {
						e.isDisable = false;
						return e;
					} else {
						e.isDisable = true;
						return e;
					}
				});
				this.subArray = res;
			},
			async qtyChange(item) {
				this.type = "Direct";
				await this.Quantity(item);
			},

			async exportImport() {
				// let message='';
				this.exportModel = true;
				// if(type==1){
				//     message = 'Are you sure want to export data?'
				// }
				// else{
				//     message = 'Are you sure want to import data?'
				// }
				// this.$Msg.confirm(message).then(async({ data }) => {
				//  console.log("add",data)
				// if(data == 1){
				//     return true
				// }
				// else{
				//  return false
				// }
				// });
			},
			range(min, max) {
				let arr = [];
				for (let i = min; i <= max; i++) {
					arr.push(i);
				}
				return arr;
			},
			async changePage(item) {
				console.log("jjjjj", item, this.value);
				if (this.value != item) {
					this.loader = true;
					this.value = item;
					await this.$store.dispatch("getCatalouge", item);
					this.data = this.$store.state.Catalouge;
					this.loader = false;
					this.$emit("input", item);
				}
			},
			async nextPage() {
				console.log("in", this.totalPages, this.value);
				if (this.value < this.totalPages) {
					this.$emit("input", this.value + 1);
					this.value = this.value + 1;
					await this.$store.dispatch("getCatalouge", this.value);
					this.data = this.$store.state.Catalouge;
					this.loader = false;
					// await this.changePage(this.value)
				}
			},
			async prevPage() {
				if (this.value > 1) {
					this.$emit("input", this.value - 1);
					this.value = this.value - 1;
					await this.$store.dispatch("getCatalouge", this.value);
					this.data = this.$store.state.Catalouge;
					this.loader = false;
					// await this.changePage(this.value)
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.modal-dialog {
		width: 100% !important;
		text-align: center;
		// margin-left: 10px;
	}
	.modal-content {
		// margin-left: 280px !important;
	}
	.alignment {
		padding-top: 12px !important;
	}
	.modal-body {
		padding: 0;
	}
	.p27 {
		padding-top: 27px !important;
	}
	.m_align {
		margin-top: 12px !important;
	}
	.pointer {
		cursor: pointer;
	}
	.form-check.form-group.csv-import-checkbox {
		display: none !important;
	}
	.form-check {
		position: relative;
		display: none !important;
		padding-left: 1.25rem;
	}
	.import {
		left: 155px;
	}
	.import-body {
		padding: 16px;
	}
	.modal-dialog {
		max-width: 80%;
	}
</style>
