<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                    >
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">My account</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <h6 class="heading-small text-muted mb-4 pl-4 pt-4">User information</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Username"
                                                        readonly
                                                        placeholder="Username"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.name"
                                            />
                                        </div>
                                        <div class="col-lg-6 pr-5">
                                            <base-input alternative=""
                                                        label="Email address" readonly
                                                        placeholder="jesse@example.com"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.email"
                                            />
                                        </div>
                                        <!-- <div class="col-lg-4 pr-5">
                                            <base-input alternative=""
                                                type="password"
                                                label="User Password"
                                                placeholder="*****"
                                                input-classes="form-control-alternative"
                                                v-model="user.password"
                                            />
                                        </div> -->
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Address -->
                                <h6 class="heading-small text-muted mb-4 pl-4">Store information</h6>
                                 <div class="pl-lg-4">
                                    <!-- <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Primary Address"
                                                        placeholder="address"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.name"
                                            />
                                        </div>
                                        <div class="col-lg-6 pr-5">
                                            <base-input alternative=""
                                                        label="Secondary address"
                                                        placeholder="address"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.email"
                                            />
                                        </div>
                                    </div> -->
                                     <!-- <div class="row">
                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                        label="City"
                                                        placeholder="city"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.store.city"
                                            />
                                        </div>
                                        <div class="col-lg-4 pr-3">
                                            <base-input alternative=""
                                                        label="State"
                                                        placeholder="state"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.store.state"
                                            />
                                        </div>
                                        <div class="col-lg-4 pr-5">
                                            <base-input alternative=""
                                                        label="Pincode"
                                                        placeholder="pincode"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.store.pincode"
                                            />
                                        </div>
                                    </div> -->
                                     <!-- <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Company name"
                                                        placeholder="co. name"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.store.company_name"
                                            />
                                        </div>
                                        <div class="col-lg-6 pr-5">
                                            <base-input alternative=""
                                                        label="Company Number"
                                                        placeholder="co. number"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.store.company_number"
                                            />
                                        </div>
                                    </div> -->
                                    
                                     <div class="row">
                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                        label="Store Name"
                                                        placeholder="name" readonly
                                                        input-classes="form-control-alternative"
                                                        v-model="user.store.name"
                                            />
                                        </div>
                                        <div class="col-lg-4 pr-3">
                                            <base-input alternative=""
                                                        label="Store Email"
                                                        placeholder="email" readonly
                                                        input-classes="form-control-alternative"
                                                        v-model="user.store.store_email"
                                            />
                                        </div>
                                        <div class="col-lg-4 pr-5">
                                            <base-input alternative=""
                                                        label="Store Number"
                                                        placeholder="number"
                                                        input-classes="form-control-alternative"
                                                        v-model="user.store.store_landline_number"
                                            />
                                        </div>
                                    </div>
                                     <div class="row">
                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                label="Store Manager Name"
                                                placeholder="Manager name" 
                                                input-classes="form-control-alternative"
                                                v-model="user.store.store_manager_name"
                                            />
                                        </div>
                                        <div class="col-lg-4">
                                            <base-input alternative=""
                                                label="Store Manager Number"
                                                placeholder="name" 
                                                input-classes="form-control-alternative"
                                                v-model="user.store.store_manager_number"
                                            />
                                        </div>
                                        <div class="col-lg-4 pr-5">
                                            <base-input alternative=""
                                                label="Opening time"
                                                placeholder="time"
                                                input-classes="form-control-alternative"
                                                v-model="user.store.store_opening_time"
                                            />
                                        </div>
                                     </div>
                                     <div class="row">
                                        <div class="col-lg-4 pr-2">
                                            <base-input alternative=""
                                                label="Closing time"
                                                placeholder="time"
                                                input-classes="form-control-alternative"
                                                v-model="user.store.store_closing_time"
                                            />
                                        </div>
                                         <div class="col-lg-4 pt-2">
                                            <label style="font-weight:600">Store Banner </label>

                                           <img :src="user.store.image" class="image_banner">
                                        </div>
                                        <input class = "mt-4 pt-3" type="file" accept="image/*" @change="uploadImage($event)" id="file-input">

                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Description -->
                                <!-- <h6 class="heading-small text-muted mb-4">About me</h6>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-input alternative=""
                                                    label="About Me">
                                            <textarea rows="4" class="form-control form-control-alternative" placeholder="A few words about you ..."></textarea>
                                        </base-input>
                                    </div>
                                </div> -->
                                <div >
                                    <base-button class="mb-4 mr-4" type="primary" size="sm" style="float:right" @click="update()">Update</base-button>
                                </div>
                               
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from '../components/BaseButton.vue';
  export default {
  components: { BaseButton },
    name: 'user-profile',
    data() {
      return {
        model: {
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          country: '',
          zipCode: '',
          about: '',
        },
        user:{
            name:'',
            email:'',
        }
      }
    },
    created(){
      if (localStorage.getItem("role") == "vendor") {
        window.location.href = "/dashboard";
      }
    },
    async mounted(){
        await this.$store.dispatch('getUser')
        this.user = this.$store.state.user
    },
    methods:{
        async update(){
            console.log("user",this.user)
            let params = {
                store_manager_name : this.user.store.store_manager_name,
                store_manager_number : this.user.store.store_manager_number,
                store_landline_number : this.user.store.store_landline_number,
                store_email : this.user.store.store_email,
                store_opening_time : this.user.store.store_opening_time,
                store_closing_time : this.user.store.store_closing_time,
                store_status : this.user.store.store_status,
                store_banner : this.user.store.store_banner
            }
            console.log("para",params);
            let res = await this.$store.dispatch('updateStore',params)
            console.log("res1",res)
            this.$Msg.success('Profile Updated Successfully', { position: 'top-right' ,timeout: 3000 });

        },
        async uploadImage(e){
            console.log("fbsdhfbsdjb",e.target.files[0])
             const image = e.target.files[0];
             console.log("image",image)
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.user.store.image = e.target.result;
                    console.log(this.user.store.image);
                };
        }
    }
  };
</script>
<style>
</style>
