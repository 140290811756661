<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
          <div class="row">
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Average Rating"
                        type="gradient-green"
                        icon="fa fa-star checked"
                        :sub-title=avg_rating
                        class="mb-4 mb-xl-0">
                    </stats-card>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                            <div class="card-header border-0"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                       Reviews
                                    </h3>
                                    </div>
                                    <div class="col text-right">
                                    <!-- <base-button type="primary" size="sm">See all</base-button> -->
                                    </div>
                                </div>
                            </div>
                
                            <div class="product_loader" v-if="this.loader"><img src="/img/loader.gif"></div>

                            <div class="table-responsive">
                                <table class="table tablesorter" :class="tableClass">
                                    <thead :class="theadClasses">
                                        <tr>
                                        <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                        </tr>
                                    </thead>

                                    <tbody :class="tbodyClasses">
                                        <tr v-for="(item,index) in reviews" :key="item.id">
                                            <td>{{index+1}}</td>
                                            <td>{{item.review}}</td>
                                            <td>{{item.review}}</td>
<!--                                            <td>{{item.customer.full_name}}</td>-->

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          </div>
                    <!-- <projects-table title="Products"  :columns=headers :data=orders></projects-table> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "../axiosCustom" 
  export default {
    name: 'tables',
    components: {
      
    },
    data () {
        return {
            loader:true,
            data:[],
            selected: [],
            selectAll: false,
            type:'',
            avg_rating:'',
            defaultPagesToDisplay: 3,
            headers : [
             { name: 'S.No'},
             { name: 'Title'},
             { name:'Comment'},
             { name:'Customer Name'},
            ]
        }
    },
    props: {
        theadClasses: {
            type: String,
            default: '',
            description: '<thead> css classes'
        },
        tbodyClasses: {
            type: String,
            default: '',
            description: '<tbody> css classes'
        },
        pageCount: {
            type: Number,
            default: 0,
            description:
            "Pagination page count. This should be specified in combination with perPage"
        },
        perPage: {
        type: Number,
        default: 10,
        description:
            "Pagination per page. Should be specified with total or pageCount"
        },
        total: {
            type: Number,
            default: 0,
            description:
            "Can be specified instead of pageCount. The page count in this case will be total/perPage"
        },
        value: {
        type: Number,
        default: 1,
        description: "Pagination value"
        },
        size: {
        type: String,
        default: "",
        description: "Pagination size"
        },
        align: {
        type: String,
        default: "",
        description: "Pagination alignment (e.g center|start|end)"
        }
  },

    computed: {
        tableClass() {
         return this.type && `table-${this.type}`;
        },
        totalPages() {
            console.log("pageCount",this.pageCount)
            if (this.pageCount > 0) return this.pageCount;
            if (this.total > 0) {
                console.log("in total");
                        return Math.ceil(this.total / this.perPage);

            }
            return 1;
        },
        pagesToDisplay() {
        if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
            return this.totalPages;
        }
        return this.defaultPagesToDisplay;
        },
        minPage() {
        if (this.value >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.value;
            if (newMaxPage > this.totalPages) {
            return this.totalPages - this.pagesToDisplay + 1;
            }
            return this.value - pagesToAdd;
        } else {
            return 1;
        }
        },
        maxPage() {
            console.log("max");
        if (this.value >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.value;
            if (newMaxPage < this.totalPages) {
            return newMaxPage;
            } else {
            return this.totalPages;
            }
        } else {
            return this.pagesToDisplay;
        }
        }
    },
    watch: {
        perPage() {
        this.$emit("input", 1);
        },
        total() {
        this.$emit("input", 1);
        }
    },
     async  mounted() {
       if (localStorage.getItem("role") == "vendor") {
         window.location.href = "/dashboard";
       } else {
         this.reviews = [];
         let token = window.$cookies.get("accessToken")
         const rating = await axios.get(`/vendor/get-ratings`, {
           headers: {
             'Authorization': `Bearer ${token}`
           }
         })
         console.log("rating", rating);
         this.avg_rating = rating.calculated_ratings.avg_rating;
         rating.ratings.map(r => {
           if (r.ratings !== null) this.reviews.push(r)
           this.loader = false;
         })
         this.loader = false;
         // await this.$store.dispatch('getUser')
         // this.userName = this.$store.state.user.name;
       }
     },
    methods: {
    }

  };
</script>
<style>
.table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}

.table td, .table th {
    text-align: center;
    
}
a.order{
    color:#2dce89;
    cursor: pointer;
}
</style>
