<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                    >
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Refund Policy</h3>
                                </div>
                                <br/>
                                <div class="col-12">
                                    <vue-editor v-model="content"></vue-editor>
                                </div>
                                
                            </div>
                        </div>
                        <div slot="footer">
                            <base-button size="sm" type="primary" @click="update">Update</base-button>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script> 
import axios from "../axiosCustom" ;
import { VueEditor } from "vue2-editor";
export default {
    components: {
      VueEditor
        
    },                            
    data(){
        return{
             content: "<h1>Some initial content</h1>"
        }
    },
    async mounted(){
        let token = window.$cookies.get("accessToken")
        const response = await axios.get('/vendor/page/refund-policy',{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        this.content = response.content
    },
    methods:{
        async update(){
            let params={
                content: this.content,
                url_key : 'refund-policy'
            }
             let token = window.$cookies.get("accessToken")
            const response = await axios.post('/vendor/update-page',params,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            console.log("/update-page", response)
        },
    },
}
</script>

<style>

</style>