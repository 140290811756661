<template>
    <div>
		<base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col">
					<div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
						<div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
							<div class="row align-items-center">
								<div class="col">
									<h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">Sale Order</h3>
								</div>
								<div class="col text-right">
									<base-button type="primary" size="sm">
										<a
											class="text-white"
											:href="`${Api}/report/sales-export?from_date=${this.startDate}&to_date=${this.endDate}&store_id=${$store.state.user.store_id}`"
										>Export</a>
									</base-button>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label for="fromDate">From Date</label>
										<input class="form-control" type="date"  v-model="startDate" />
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="fromDate">To Date</label>
										<input class="form-control" type="date"  v-model="endDate" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as API from '../../API'
export default {
    name: "SaleOrder",
    data() {
			return {
				startDate: "",
				endDate: "",
                Api:API.baseURL
			};
		},
		methods: {
			handleReport() {

				return `https://${API.baseURL}/report/product-export?from_date=${this.startDate}&to_date=${this.endDate}`;
			}
		},
  created() {
    if (localStorage.getItem("role") == "vendor") {
      window.location.href = "/dashboard";
    }
  }
}
</script>