<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                            <div class="card-header border-0"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                       {{pageType}} Request
                                    </h3>
                                    </div>
                                    <div class="col text-right">
                                    <!-- <base-button type="primary" size="sm">See all</base-button> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="openModel== true">
                                <div class="modal" style="display:block; width:100%">
                                <div class="modal-dialog">
                                    <div class="modal-content ml-279 t-200" style="width:300px; ">
                                        <div class="modal-header"><h3 class="modal-title">Accept/Reject request</h3>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="openModel=false">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body row">
                                            <div class="col-4 mr-6 ml-2"> <base-button type="primary" size="sm" @click="action('accept')">Accept</base-button></div>
                                            <div class="col-4"><base-button type="primary" size="sm" @click="action('reject')">Reject</base-button> </div>
                                        </div>
                                        
                                    </div>
                                </div>
                             </div>
                            </div> -->
                            <div class="product_loader" v-show="loader == true"><img src="/img/loader.gif"></div>

                            <div class="table-responsive">
                                <table class="table tablesorter" :class="tableClass">
                                    <thead :class="theadClasses">
                                        <tr>
                                        
                                        <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                        </tr>
                                    </thead>

                                    <tbody :class="tbodyClasses">
                                        <tr v-for="item in data" :key="item.id">
                                         <td><a @click="details(item)" class="order"><b>#{{item.item_id}}</b></a></td>
                                          <td v-if="isVendor && item.store_data != null && item.store_data.name != null">{{item.store_data.name}}</td>
                                          <td v-if="item.store_data == null || item.store_data.name == null"></td>
                                          <td >{{item.item.created_at || ''}}</td>
                                         <td class="product-details"><img :src="`https://images.wndw.in/live/inside/100x100/`+item.item.image.image" width="45px" height="auto">
                                                <span class="pt-0 pl-4" style="display:inline-block">{{item.item.name}}<br/>  <b> {{item.item.sku}}</b></span></td>
                                                 <td >{{item.item.return_type ? item.item.return_type.replace('_'," ") : ''}}</td>
                                                  <td >{{item.item.return_reason ? item.item.return_reason: ''}}</td>
                                        
                                        <td v-if="item.customer !== null">{{item.customer.full_name}}</td><td v-else></td>  
                                        <td >{{item.item.price}}</td>
                                        <td >{{item.item.order_status || ''}}</td> 
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                              <div v-if="data.length==0" class="card-footer d-flex justify-content-center"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <p>No item for return</p>
                              </div>
                            <div  class="card-footer d-flex justify-content-end"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                            <!-- <base-pagination total=170></base-pagination> -->
                            <template v-if="data.length>0">
                                <ul class="pagination" :class="[size && `pagination-${size}`, align && `justify-content-${align}`]">
                                    <li class="page-item prev-page" :class="{disabled: value === 1}">
                                    <a class="page-link" aria-label="Previous" @click="prevPage">
                                        <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                                    </a>
                                    </li>
                                    <li class="page-item" :class="{active: value === item}"
                                        :key="item"
                                        v-for="item in range(minPage, maxPage)">
                                    <a class="page-link" @click="changePage(item)">{{item}}</a>
                                    </li>
                                    <li class="page-item next-page" :class="{disabled: value === totalPages}">
                                    <a class="page-link" aria-label="Next" @click="nextPage">
                                        <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </a>
                                    </li>
                                </ul>
                                </template>
                            </div>

                          </div>
                    <!-- <projects-table title="Products"  :columns=headers :data=orders></projects-table> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "../axiosCustom" ;
  export default {
    name: 'tables',
    components: {
      
    },
    data () {
        return {
            key: 'all',
            isVendor: false,
            loader:true,
            data:[],type:'',
            selected: [],
            page:1,
            selectAll: false,
            openModel:false,
            defaultPagesToDisplay: 3,
            headers : [
             { name: 'Date/Time',value:'date'},
             { name:'Product',value:'store'},
              { name:'Return Type',value:'status'},
               { name:'Return Reson',value:'status'},

             { name:'Customer',value:'store'},
             { name:'Price',value:'status'},
            //  { name:'Type',value:'status'},
             { name:'Item Status',value:'status'},

            ]
        }
    },
    props: {
        theadClasses: {
            type: String,
            default: '',
            description: '<thead> css classes'
        },
        tbodyClasses: {
            type: String,
            default: '',
            description: '<tbody> css classes'
        },
        pageCount: {
            type: Number,
            default: 0,
            description:
            "Pagination page count. This should be specified in combination with perPage"
        },
        perPage: {
        type: Number,
        default: 10,
        description:
            "Pagination per page. Should be specified with total or pageCount"
        },
        total: {
            type: Number,
            default: 0,
            description:
            "Can be specified instead of pageCount. The page count in this case will be total/perPage"
        },
        value: {
        type: Number,
        default: 1,
        description: "Pagination value"
        },
        size: {
        type: String,
        default: "",
        description: "Pagination size"
        },
        align: {
        type: String,
        default: "",
        description: "Pagination alignment (e.g center|start|end)"
        }
  },
    created() {
      if(localStorage.getItem("role") == "vendor"){
        this.isVendor = true;
        this.headers.unshift({ name: "Store", value: "stores" })
      }
      this.headers.unshift({ name: 'Id',value:'id'})

    },
    computed: {
        pageType () {
      let pageType = 'All'
      let key = this.$route.query.key
      switch (key) {
        case 'c': {
          pageType = 'Completed'
          break;
        }
        case 'p': {
          pageType = 'Pending'
          break;
        }
       
      }
      return pageType

    },
        tableClass() {
         return this.type && `table-${this.type}`;
        },
        totalPages() {
            console.log("pageCount",this.pageCount)
            if (this.pageCount > 0) return this.pageCount;
            if (this.total > 0) {
                console.log("in total");
                        return Math.ceil(this.total / this.perPage);

            }
            return 1;
        },
        pagesToDisplay() {
        if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
            return this.totalPages;
        }
        return this.defaultPagesToDisplay;
        },
        minPage() {
        if (this.value >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.value;
            if (newMaxPage > this.totalPages) {
            return this.totalPages - this.pagesToDisplay + 1;
            }
            return this.value - pagesToAdd;
        } else {
            return 1;
        }
        },
        maxPage() {
            console.log("max");
        if (this.value >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.value;
            if (newMaxPage < this.totalPages) {
            return newMaxPage;
            } else {
            return this.totalPages;
            }
        } else {
            return this.pagesToDisplay;
        }
        }
    },
    watch: {
        async $route () {  
            await this.$store.dispatch('getReturns',{item: this.page, key:this.$route.query.key});
            this.data = this.$store.state.returns
        },
        perPage() {
        this.$emit("input", 1);
        },
        total() {
        this.$emit("input", 1);
        }
    },
    async mounted(){
       
        this.data=[];
        await this.$store.dispatch('getReturns',{item: this.page, key:this.$route.query.key})
        this.key = this.$route.query.key
        this.total = (this.$store.state.totalReturns)
       
        console.log("return",this.$store.state.returns)
        this.data = this.$store.state.returns
        console.log("data",this.data)
        this.loader = false;
      
    },
    
     methods: {
     select() {
        this.selected = [];
        if (!this.selectAll) {
          for (let i in this.data) {
            this.selected.push(this.data[i].id_product);
          }
        }
      },
      range(min, max) {
        let arr = [];
        for (let i = min; i <= max; i++) {
            arr.push(i);
        }
        return arr;
        },
        async changePage(item) {
            if(this.value != item){
                this.loader = true;
                this.value = item
                await this.$store.dispatch('getReturns',{item: this.page, key:this.$route.query.key})
                this.data = this.$store.state.returns
                this.loader = false;
                this.$emit("input", item);
            }
        },
        async nextPage() {
            if (this.value < this.totalPages) {
                this.value = this.value + 1;
                await this.changePage(this.value)
                this.$emit("input", this.value + 1);
            }

        },
       async  prevPage() {
        if (this.value > 1) {
            this.$emit("input", this.value - 1);
            this.value = this.value - 1;
            await this.changePage(this.value)
        }
        },
        async details(item){
            this.currentItem = item
            console.log("this.currentItem",this.currentItem)
            this.$router.push({
                name: "Return Details",
                query: { id: this.currentItem.id},
                params: { item: item },
            });
   
            // console.log("item",this.currentItem);
            // if(this.currentItem.is_vendor_action_completed == 1){
            //      this.openModel =  false;
            //      return;
            // }
            // else{
            //   this.openModel =  true;

            // }
        },
        async action(type){
            try{
                let params = {
                rid: this.currentItem.id,
                item_id: this.currentItem.item_id,
                approve_status : type === 'accept' ? 1 : 0
                }
                let token = window.$cookies.get("accessToken")
                const response = await axios.post('/vendor/accept-reject-return-exchange-request',params,{
                headers: {
                'Authorization': `Bearer ${token}`
               }
                })
                location.reload();

              if(response.message == 'Success'){
                  this.$Msg.success(response.message, { position: 'top-right' ,timeout: 3000 });
                this.$router.push('/returns')
                this.openModel =  false;
                // await this.$store.dispatch('getReturns',this.page)
                this.total = (this.$store.state.totalReturns)
                this.data= this.$store.state.returns
                this.loader = false;
              }

             }
              catch  (error)
              {
                  this.$Msg.error(error.message, { position: 'top-right' ,timeout: 3000 });
            }
     }
     }
  };
</script>
<style>
.table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}
.table td, .table th {
    text-align: center;
    
}
a.order{
    color:#2dce89;
    cursor: pointer;
}
.modal-content {
    left: 126px;
    }
</style>
