<template>
  <div>

    <div v-if="showMessage">
      <div class="modal" style="display: block; width: 100%">
        <div class="modal-dialog">
          <div class="modal-content t-300" style="width: 100%">
            <div class="modal-header">
              <h3 class="modal-title">Personilization Message</h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
               @click="showMessage=false"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p style="padding-left:1rem;padding-right:1rem;padding-bottom:1.5rem;margin-bottom:0">{{ordermessage}}</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-left">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid pl-0 align-items-left m-0">
        <div class="row">
          <div class="col-lg-10 ml-0 pl-0">
            <h3 class="text-white">
              <b>#{{ id }}</b>
            </h3>
          </div>
          <div class="col-lg-2 mr-0 pr-0 ml-0"></div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7" v-if="error == true">
      <div class="row">
        <div class="col-xl-12">
          <br />
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center"></div>
            </div>
            <template class="p-0">
              <table class="table tablesorter" :class="tableClass">
                <thead :class="theadClasses">
                  <tr>
                    <th v-for="item in headers" :key="item.id">
                      {{ item.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <p class="text-center">No Item found</p>
                </tbody>
              </table>
            </template>
          </card>
        </div>
      </div>
    </div>

    <div class="container-fluid mt--7" v-if="error == false">
      <div class="row">
        <div class="col-xl-8">
          <br />
          <!-- <div v-if="totalItems.length > 0">
            <card shadow type="secondary">
              <div slot="header" class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Orders</h3>
                  </div>
                </div>
              </div>
              <template class="p-0">
                <table class="table tablesorter" :class="tableClass">
                  <thead :class="theadClasses">
                    <tr>
                      <th v-for="item in headers" :key="item.id">
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="tbodyClasses">
                    <tr v-for="(item, i) in totalItems" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td style="width: 20%">{{ item.name }}</td>
                      <td>{{ item.qty }}</td>
                      <td>{{ item.price }}</td>
                      <td>{{ item.qty * item.price }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <div slot="footer" class="bg-white border-0">
                <base-button
                  type="primary"
                  size="sm"
                  style="float: right"
                  @click="ship(item)"
                  >Mark Ready</base-button
                >
              </div>
            </card>
            <br />
          </div> -->
          <div>
            <card  shadow type="secondary" v-if="totalReadyItems.length > 0 && totalReadyItems.filter(e=>e.delivery_partner=='seller').length>0">
              <div slot="header" class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h3 class="mb-0">Self Fulfilled </h3>
                  </div>
                  <div class="col-6">
                    <base-button
                    type="primary"
                    size="sm"
                    class="float-right" 
                    @click="fullfilment(totalReadyItems[0])"
                    >Self fullfilment</base-button
                  >
                  </div>
                </div>
              </div>
              <template >
                
                <table class="table tablesorter" :class="tableClass">
                  <thead :class="theadClasses">
                    <tr>
                      <th v-for="item in headersReady" :key="item.id">
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="tbodyClasses">
                    <tr v-for="(item, i) in totalReadyItems.filter(e=>e.delivery_partner=='seller')" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td class="product-details">
                        <img
                          :src="`https://images.wndw.in/live/inside/100x100/`+item.image.image"
                          background-color:red
                          width="45px"
                          height="auto"
                          style="float: left"
                        />
                        <span
                          >{{ item.name }}<br /><b>SKU:{{ item.sku }}</b></span
                        >
                      </td>
                      <td>{{ item.qty }}</td>
                       
                      <td>{{ item.selling_price.toFixed(2) }}</td>
                      <td>
                        {{ item.qty * (item.row_total.toFixed(2)) }}
                      </td>
                      <td>
                       <span class="orderStatus" :class="(item.order_status == 'delivered') ? 'orderDelivered' : ''" > {{item.order_status}} </span> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template> </card
            >
            <card shadow class="mb-4" type="secondary" v-else-if="totalReadyItems.length > 0 && totalReadyItems.filter(e=>e.delivery_partner=='wndw').length>0">
              <div slot="header" class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">WNDW Fulfilled</h3>
                  </div>
                </div>
              </div>
              <template>
                

               
                <table class="table tablesorter" :class="tableClass">
                  <thead :class="theadClasses">
                    <tr>
                      <th v-for="item in headersReady" :key="item.id">
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="tbodyClasses">
                    
                    
                    <tr v-for="(item, i) in totalReadyItems.filter(e=>e.delivery_partner=='wndw')" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td class="product-details">
                        <img
                          v-if="item.image != null"
                          :src="`https://images.wndw.in/live/inside/100x100/`+item.image.image"
                          background-color:red
                          width="45px"
                          height="auto"
                          style="float: left"
                        />
                        <span
                          >{{ item.name }}<br /><b>SKU:{{ item.sku }}</b></span
                        >
                      </td>
                      <td>{{ item.qty }}</td>
                      <td>  <span class="personilizebtn" v-if="item.personalization_message!=null" @click="showPersonilization(item.personalization_message)">Yes</span>  <span v-else>No</span>  </td>
                      <td>{{ item.selling_price.toFixed(2) }}</td>
                      <td>
                        {{ item.qty * (item.row_total.toFixed(2)) }}
                      </td>
                      <td>
                        <span class="orderStatus" > {{item.order_status}} </span> 
                      </td>
                    </tr>
                  </tbody>
                </table>
                
              </template> </card
            > 
            <card shadow type="secondary" v-else>
              <div slot="header" class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Orders</h3>
                  </div>
                </div>
              </div>
              <template>
                <table class="table tablesorter" :class="tableClass">
                  <thead :class="theadClasses">
                    <tr>
                      <th v-for="item in headersReady" :key="item.id">
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="tbodyClasses">
                    
                    
                    <tr v-for="(item, i) in totalReadyItems" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td class="product-details">
                        <img
                          :src="item.image.image"
                          background-color:red
                          width="45px"
                          height="auto"
                          style="float: left"
                        />
                        <span
                          >{{ item.name }}<br /><b>SKU:{{ item.sku }}</b></span
                        >
                      </td>
                      <td>{{ item.qty }}</td>
                      <td>{{ item.selling_price.toFixed(2) }}</td>
                      <td>
                        {{ item.qty * (item.row_total.toFixed(2)) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                
              </template> </card
            >
         
          <!-- <div v-else>
            
            <card shadow type="secondary" v-if="totalReadyItems.length > 0">
              <div slot="header" class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Orders</h3>
                  </div>
                </div>
              </div>
              <template>
                <table class="table tablesorter" :class="tableClass">
                  <thead :class="theadClasses">
                    <tr>
                      <th v-for="item in headersReady" :key="item.id">
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="tbodyClasses">
                    
                    
                    <tr v-for="(item, i) in totalReadyItems" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td class="product-details">
                        <img
                          :src="item.image.image"
                          background-color:red
                          width="45px"
                          height="auto"
                          style="float: left"
                        />
                        <span
                          >{{ item.name }}<br /><b>SKU:{{ item.sku }}</b></span
                        >
                      </td>
                      <td>{{ item.qty }}</td>
                      <td>{{ item.selling_price.toFixed(2) }}</td>
                      <td>
                        {{ item.qty * (item.row_total.toFixed(2)) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                
              </template> </card
            >
            <br>
          </div> -->
           </div>
           <br>
          <card shadow>
            <div slot="header" class="bg-white">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Payment</h3>
                </div>
              </div>
            </div>
            <div class="mb-4 mt-4">
              <div class="row m-0">
                <div class="col-6 p-2 pl-4">Subtotal</div>
                <div class="col-6 p-2 pr-4" style="text-align: right">
                  ₹ {{ numberFormate(data.order_subtotal) }}
                </div>
              </div>

              <div class="row m-0">
                <div class="col-6 p-2 pl-4">Shipping</div>
                <div class="col-6 p-2 pr-4" style="text-align: right">
                  ₹ +{{ numberFormate(data.shipping_charge) }}
                </div>
              </div>
              <div class="row m-0" v-if="data.discount_amount>0">
                <div class="col-6 p-2 pl-4">Discount</div>
                <div class="col-6 p-2 pr-4" style="text-align: right">
                  ₹ -{{ numberFormate(data.discount_amount) }}
                </div>
              </div>
              <div class="row m-0">
                <div class="col-6 p-2 pl-4"><b>Total</b></div>
                <div class="col-6 pr-4 p-2" style="text-align: right">
                  ₹ {{ numberFormate(data.grand_total) }}
                </div>
              </div>
            </div>
          </card>
             <!-- Prescription Code Starts -->
      <div class="prescription" v-if="data.prescription">
        <div v-for="(item,index) in data.prescription"  :key="index">
          
        <button class="prescrip"><a style="color:white" :href="data.prescription[index].image_url"  target="_blank">Prescription{{index+1}}</a><br/></button>
        </div>
      </div>
      <div v-if="isPrescription == true">
                <div class="modal" style="display: block; width: 100%">
                  <div class="modal-dialog">
                    <div class="modal-content t-300" style="width: 790px">
                      <div class="modal-header">
                       <h4>Prescription</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          @click="closePrescription"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <template>
                          <table class="table tablesorter" :class="tableClass">
                            <thead :class="theadClasses">
                            </thead>
                            <tbody :class="tbodyClasses">
                              <tr>
                                <td ><img class="prespic" :src="prescriptionData.image_url" ></td>
                              </tr>
                                <tr>
                                  <td><button>
                      <a :href="prescriptionData.image_url"  target="_blank">View Full Image</a> </button>
                      
                      </td>
                                </tr>
                            </tbody>
                          </table>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      <!-- Prescription Code Ends -->
        </div>
        <div class="col-xl-4 margin pt-1" style="height: 50px">
          <div class="card card-profile shadow">
            <div class="justify-content-center">
              <div class="col-12 order-lg-2 mt-4 mb-3" style="display: flex">
                <i class="fas fa-clock top-2 mr-3 pt-1" aria-hidden="true"></i>
                <div class="" style="text-align: right">{{ timeslot }}</div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="justify-content-center">
              <div class="col-12 pl-0 pr-0" style="display: flex">
                <base-button
                  type="primary"
                  size="lg"
                  style="width: 50%"
                  @click="generateInvoice(item)"
                  >Create Invoice</base-button
                >

                <base-button
                  type="primary"
                  size="lg"
                  style="width: 50%"
                  @click="printModel()"
                  >Print Slip</base-button
                >
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="justify-content-center">
              <div class="col-12 pl-0 pr-0" style="display: flex">
                 
              <input type="text" placeholder="POS invoice#" v-model="invoiceNo" class="form-control form-control-alternative mr-3"  />
    
                <base-button
                  type="primary"
                  size="md"
                  style="width: 50%"
                  @click="saveInvoiceNo"
                  >Save</base-button
                >
              </div>
            </div>
          </div>
          <div class="card card-profile shadow mt-3">
            <div class="justify-content-center">
              <div class="col-lg-12 order-lg-12 mt-3">
                <h2>Customer</h2>
              </div>
              <div class="card-profile-stats d-flex p-0">
                <div>
                  <span v-if="data.customer" style="float: left">{{
                    data.customer.full_name
                  }}</span
                  ><br />
                  <span style="float: left">{{ data.order_items.length }} Orders</span>
                </div>
              </div>
            </div>
            <hr class="my-4 mb-0 mt-0" />
            <div class="justify-content-center">
              <div class="col order-lg-6">
                
                <h3>Contact information</h3>
              </div>
              <div class="card-profile-stats d-flex p-0">
                <div>
                  <!-- <span style="float: left">Test@test.com</span><br /> -->
                  <span v-if="data.customer" style="float: left">{{
                    data.customer.phone
                  }}</span>
                </div>
              </div>
            </div>
            <hr class="my-4 mb-0 mt-0" />
            <div class="justify-content-center">
              <div class="col order-lg-6">
                <h3>Shipping Address</h3>
              </div>
              <div class="card-profile-stats  p-0">
                <div>
                  <span style="float: left ; text-align: left">{{data.customer.street_address}}</span><br />
                  <span style="float: left"> {{data.customer.state}}</span>
                </div>
              </div>
            </div>
            <hr class="my-4 mb-0 mt-0" />
            <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div class="d-flex justify-content-between"></div>
            </div>
            <div class="card-body pt-0 pt-md-4"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="openShipmentModel == true">
      <div class="modal" style="display: block; width: 100%">
        <div class="modal-dialog">
          <div class="modal-content t-300" style="width: 790px">
            <div class="modal-header">
              <h3 class="modal-title">Manual Fulfillment</h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="openShipmentModel = false"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <template>
                <table class="table tablesorter" :class="tableClass">
                  <thead :class="theadClasses">
                    <tr>
                      <th v-for="item in headers" :key="item.id">
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="tbodyClasses">
                    <tr v-for="(item, i) in totalItems" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>
                        <div class="qty_box mt-0">
                          <a @click="Quantity(item, i, 0)"><i class="fa fa-minus"></i></a>
                          <input
                            type="text"
                            v-model="item.qty"
                            @input="qtyChange(item, i)"
                          />
                          <a @click="Quantity(item, i, 1)"><i class="fa fa-plus"></i></a>
                        </div>
                      </td>
                      <td>{{ item.selling_price.toFixed(2) }}</td>
                      <td>
                        {{ item.qty * (item.selling_price.toFixed(2)) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
            <div class="modal-footer">
              <base-button
                type="primary"
                size="sm"
                style="float: right"
                @click="readyForShipment(totalItems)"
                >Fulfilled</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="openPrintModel == true">
      <div class="modal" style="display: block; width: 100%">
        <div class="modal-dialog">
          <div class="modal-content t-300" style="width: 790px">
            <div class="modal-header">
              <h3 class="modal-title">Print Slip</h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="openPrintModel = false"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <template>
                <table class="table tablesorter" :class="tableClass">
                  <thead :class="theadClasses">
                    <tr>
                      <th v-for="item in headersPrint" :key="item.id">
                        {{ item.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="tbodyClasses">
                    <tr v-for="(item, i) in totalReadyItems" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.qty }}</td>

                      <td>{{ item.selling_price.toFixed(2) }}</td>
                      <td>
                        {{ item.qty * (item.selling_price.toFixed(2)) }}
                      </td>
                      <td>
                        <label class="form-checkbox">
                          <input type="checkbox" :value="i" v-model="item.selected" />
                          <i class="form-icon"></i>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
            <div class="modal-footer">
              <base-button
                type="primary"
                size="sm"
                style="float: right"
                @click="printSlip(totalReadyItems)"
                >Print</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "../axiosCustom";
export default {
  name: "user-profile",
  components: {},
  data() {
    return {
      ordermessage:'',
      showMessage:false,
      prescriptionData:[],
      isPrescription :false,
      invoiceNo: '',
      windowPrintCall: false,
      editable: false,
      timeslot: "",
      type: "",
      error: false,
      openShipmentModel: false,
      openPrintModel: false,
      subArray: [],
      data: [],
      isactive: "false",
      selected: true,
      totalItems: [],
      totalReadyItems: [],
      model: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        country: "",
        zipCode: "",
        about: "",
      },
      headersReady: [
        { name: "S.no" },
        { name: "Product" },
        { name: "Qty" },
        {name:'Message'},
        { name: "Price" },
        { name: "Amount" },
        {name: "Status"}
        // {name:'Action'}
      ],
      headersPrint: [
        { name: "S.no" },
        { name: "Product" },
        { name: "Qty" },
        { name: "Price" },
        { name: "Amount" },
        { name: "Action" },
      ],
      headers: [
        { name: "S.no" },
        { name: "Product" },
        { name: "Qty" },
        { name: "Price" },
        { name: "Amount" },
      ],
      actionHeaders: [
        { name: "S.no" },
        { name: "Product" },
        { name: "Qty" },
        { name: "Price" },
        { name: "Amount" },
        { name: "Action" },
      ],
      id: "",
      key:''
    };
  },
  props: {
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  async created() {},
  methods: {
    showPersonilization(msg){
      this.showMessage=true
      this.ordermessage=msg
    },
    viewPrescription(item)
    {
      this.isPrescription = true;
      console.log(this.data);
      console.log(this.isPrescription);
      this.prescriptionData = item
    },
    closePrescription(){
      this.isPrescription = false;
      this.prescriptionData ="";
    },
    fullfilment(item){
      this.$router.push({
        name: "Fullfillment",
        query: { id: item.order_quote_id, },
        // params: { item: item },
      });
      
      // this.$router.push("Fullfillment");
    
    },
     async saveInvoiceNo () {
      // var payload = new FormData ()
        let id = this.$store.state.details.order_quote_id;
      let form =  {'order_id': id,'invoice_number': this.invoiceNo}
       this.$store.dispatch('updateOrderInvoice',form).then((res)=> {
         if(res.status == 'success') {
           this.$Msg.success(res.message, {
            position: "top-center",
            timeout: 3000,
        });
         } else {
           this.$Msg.error(res.message, {
            position: "top-center",
            timeout: 3000,
        });
         }

       })

    },
    numberFormate(data) {
      return new Intl.NumberFormat("en-IN").format(data);
    },
    ship() {
      console.log("llll");
      this.openShipmentModel = true;
      this.shipment = [];
      this.totalItems.map((e) => {
        return this.shipment.push(e.qty);
      });
    },
    generateInvoice() {
      let id = this.$store.state.details.order_quote_id;
      const basePath = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}`:''}`
      //window.open(`http://lhmvendor.greenhonchos.com/invoice?id=${id}`, "_blank");
      window.open(`${basePath}/invoice?id=${id}`, "_blank")
      //  window.open(
      //   `http://localhost:8081/invoice?id=${id}`,
      //   "_blank"
      // );
    },
    printModel() {
      this.openPrintModel = true;
      this.totalReadyItems.forEach((element) => {
        element.selected = true;
      });
    },
    printSlip() {
      let ids = [];
      const basePath = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}`:''}`
      this.totalReadyItems.forEach((e) => {
        if (e.selected == true) {
          ids.push(e.item_id);
        }
      });
      this.$cookies.set("ids", JSON.stringify(ids));
      let res = window.open(`${basePath}/slip?id=${this.totalReadyItems[0].order_quote_id}`, "_blank");
      //  let res = window.open(
      //   `http://localhost:8081/slip?id=${this.totalReadyItems[0].order_quote_id}`,
      //   "_blank"
      // );
      this.openPrintModel = false;
      if (res == undefined) this.$cookies.remove("ids", JSON.stringify(ids));
    },
    printInvoice() {
      window.print();
      window.onbeforeprint = () => {
        console.log("Before print");
        this.windowPrintCall = true;
      };
    },
    async Quantity(item, index, type) {
      if (this.shipment[index] < this.totalItems[index].qty) {
        this.totalItems[index].qty = this.shipment[index];
        return;
      }
      this.item = item;
      if (type == 0) {
        if (this.totalItems[index].qty - 1 < 0) {
          return;
        }
        this.totalItems[index].qty = this.totalItems[index].qty - 1;
      }
      if (type == 1) {
        if (this.totalItems[index].qty + 1 > this.shipment[index]) {
          return;
        }
        this.totalItems[index].qty = this.totalItems[index].qty + 1;
      }
      var res = this.totalItems.map((e) => {
        if (e.id_product == this.item.id_product) {
          e.isDisable = false;
          return e;
        } else {
          e.isDisable = true;
          return e;
        }
      });
      this.totalItems = res;
    },
    async qtyChange(item, index) {
      this.type = "Direct";
      await this.Quantity(item, index);
    },
    async readyForShipment(data) {
      var token = window.$cookies.get("accessToken");
      console.log("data", data);

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].qty; j++) {
          let params = {
            oid: this.data.timing.id,
            item_id: data[i].item_id,
            order_id: this.id,
            store_name: this.user.store.name,
            store_location: this.user.store.location_name,
          };
          console.log("params", params);
          const res = await axios.post("/vendor/order-ready", params, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log("res", res);
          if (res.message == "Success") {
            this.getItem();
          }
        }
      }
      this.openShipmentModel = false;
    },
    async getItem() {
   
        this.totalReadyItems=[]
    
      if (this.$route.params.type == "edit") {
        this.editable = true;
      }

      await this.$store.dispatch("getOrderDetails", this.$route.query.id);

      if (this.$store.state.details.order_items == undefined) {
        this.totalReadyItems = [];
        this.error = true;
      } else {
        this.id = this.$route.query.id;
        if (this.$store.state.details.timing !== null)
        
          this.timeslot = `${this.$store.state.details.time_slot}, ${this.$store.state.details.time_slot_date}`;
          let key=this.$route.query.key
         if(key=='Pending'){
            this.$store.state.details.order_items.map((e) => {
              if (e.order_status == "accepted" ||e.order_status == "ready_for_shipment") {
                this.totalReadyItems.push(e);
              }
            });
          }
          else if(key=='Completed'){
            this.$store.state.details.order_items.map((e) => {
              if (e.order_status == "delivered") {
                this.totalReadyItems.push(e);
              }
            });
          }
          else if(key=='Failed'){
            this.$store.state.details.order_items.map((e) => {
              if (e.order_status == "cancelled"||
                  e.order_status == "rejected"||
                  e.order_status == "auto_cancelled"||
                   e.order_status == "admin_cancelled"
              ) {
                this.totalReadyItems.push(e);
              }
            });
          }
          else if(key==undefined){
            this.$store.state.details.order_items.map((e) => {
              if (e.order_status == "accepted" ||
                e.order_status == "delivered" ||
                e.order_status == "ready_for_shipment"||
                e.order_status == "cancelled"||
                e.order_status == "rejected"||
                e.order_status == "auto_cancelled"||
                e.order_status == "admin_cancelled"||
                e.order_status == "pending") {
                this.totalReadyItems.push(e);
              }
            });
          }
 
        this.data = this.$store.state.details;
        this.invoiceNo = this.$store.state.details.invoice_number
      }
    },
  },
  async mounted() {
     this.key = this.$route.query.key
    this.getItem();
    await this.$store.dispatch("getUser");
    this.user = this.$store.state.user;
  },
};
</script>

<style scoped>

.orderStatus{
    text-transform: capitalize;
    color: #fff;
    background-color: #5e72e4;
    padding: 3px 6px;
    font-size: 12px;
    border-radius: 4px;
    white-space: initial;
}
.orderStatus.orderDelivered{
  background-color: green;
}
.table td{
    white-space: initial !important;
}
.product-details{
  display: flex;
}
.table thead th {
  border: 0;
}
.container-fluid.align-items-left {
  padding-left: 13px !important;
}
.modal-content {
  left: 0px;
  top: 65px;
}
.modal-body {
  padding: 0 !important;
}
.table td {
  white-space: break-spaces;
}
.prescrip {
  margin-top: 8px;
  background-color: #5e72e4;
  margin-bottom: 8px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.personilizebtn{
  cursor:pointer;
  color:#007bff;
  font-weight: bold;
}
</style>
