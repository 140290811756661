<template>
    <div>
        <div class="product_loader" v-if="this.loader"><img src="/img/loader.gif"></div>
        <div v-if="openModel== true">
            <div class="modal" style="display:block; width:100%">
                <div class="modal-dialog">
                    <div class="modal-content ml-279 t-200" style="width:100%;">
                        <div class="modal-header"><h3 class="modal-title">Please Tell Reason for Rejection</h3>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="openModel=false">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <input type="text" v-model="rejection_reason" style="width:100%">
                            <div class="col-4 mr-6 ml-2 mt-3"> <base-button type="primary" size="sm" @click="confirmreject('reject')">Submit</base-button></div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div v-if="rejectModel== true">
            <div class="modal" style="display:block; width:100%">
                <div class="modal-dialog">
                    <div class="modal-content ml-279 t-200" style="width:100%;">
                        <div class="modal-header"><h3 class="modal-title">Please Tell Reason for Rejection</h3>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="rejectModel=false">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <input type="text" v-model="rejection_reason" style="width:100%">
                            <div class="col-4 mr-6 ml-2 mt-3"> <base-button type="primary" size="sm" @click="action('reject')">Submit</base-button></div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-left">
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
            <div class="container-fluid pl-0 align-items-left m-0">
                <div class="row">
                    <div class="col-lg-10 ml-0 pl-0 ">
                        <h3 class="text-white "><b>#{{id}}</b></h3>
                    </div>
                    <div class="col-lg-2 mr-0 pr-0 ml-0 ">
                    </div>
                </div>

            </div>
        </base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Return Items</h3>
                                </div>
                            </div>
                        </div>
                        <template class="p-0">
                         <table class="table tablesorter " :class="tableClass">
                            <thead :class="theadClasses">
                                <tr>
                                    <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                </tr>
                            </thead>
                            <tbody :class="tbodyClasses">
                                <tr v-for="(item , i) in return_detail" :key="i">
                                   <td>{{item.item.name}}</td>
                                    <td>{{item.return_reason_value.reason}}</td>
                                    <td>{{item.type}}</td>
                                    <td>{{item.return_type}}</td>
                                </tr>
                            </tbody>
                        </table>
                        </template>
                    </card><br/>
                </div>
                </div>
        </div>

        <div class="col-lg-12 mr-3 pr-0 ml-0">
            <base-button
          type="primary"
          v-if=" (return_detail[0].return_type=='in_store')"
          @click="markAsCompleted('accept')"
          > Mark as Completed</base-button>
        <base-button
          type="primary"
          v-if="return_detail[0].notification_status=='pending' && (return_detail[0].return_type=='seller_pickup' || return_detail[0].return_type=='in_store' || return_detail[0].pickup_status!=null)"
          @click="action('accept')"
          > Accept</base-button
        >
        <base-button
          type="primary"
          v-if="return_detail[0].notification_status=='pending' && (return_detail[0].return_type=='seller_pickup' || return_detail[0].return_type=='in_store' || return_detail[0].pickup_status!=null)"
         @click="return_reject()"
          style="margin-right:6px;background-color:red; border:red; height:44px"
          >Reject</base-button
        >
        <base-button
          type="primary"
           v-if="return_detail[0].return_type=='seller_pickup' || return_detail[0].return_type=='wndw_pickup' && this.return_detail[0].pickup_status==null"
          @click="confirmreject('accept')" 
          >Confirm Pickup Request</base-button> 
        <base-button
          type="primary"
          @click="reject()"
          v-if="return_detail[0].return_type=='seller_pickup' || return_detail[0].return_type=='wndw_pickup' && this.return_detail[0].pickup_status==null"
          style="margin-right:5px;background-color:red; border:red; height:44px"
          >Reject Pickup Request</base-button>
      </div>
   <br>
        <card shadow style="width:94%;margin:0 auto">
            
            <div class="mb-4 mt-4">
              <div class="row m-0">
                <div class="col-6 p-2 pl-4"><b>Timeslot : </b></div>
                <div class="col-6 p-2 pr-4" style="text-align: center">
                {{this.return_detail[0].scheduled_date}} , {{this.return_detail[0].scheduled_time_slot}}
                <span v-if="this.return_detail[0].return_type=='in_store' && this.return_detail[0].type=='return'"> In store Return</span>
                <span v-if="this.return_detail[0].return_type=='in_store' && this.return_detail[0].type=='exchange'"> In store Exchange</span>
                </div>
              </div>

              <div class="row m-0">
                <div class="col-6 p-2 pl-4"><b>comment : </b></div>
                <div class="col-6 p-2 pr-4" style="text-align: center">
                 {{this.return_detail[0].return_reason}}
                </div>
              </div>
              <div class="row m-0">
                <div class="col-6 p-2 pl-4"><b>status : </b></div>
                <div class="col-6 p-2 pr-4" style="text-align: center;color:red">
                 <span v-if="this.return_detail[0].rejection_process">Rejection in process</span>
                 <span v-else>{{this.return_detail[0].item.order_status}}</span>
                </div>
              </div>
              <div class="row m-0" v-if="this.return_detail[0].pickup_status!=null">
                <div class="col-6 p-2 pl-4"><b>Pickup Status : </b></div>
                <div class="col-6 pr-4 p-2" style="text-align: center;color:red">
                 {{this.return_detail[0].pickup_status}}
                </div>
              </div>
         
            </div>
        </card>
   

        <div class="col-xl-12 mt-4" v-if="this.return_detail[0].images.length>0">
            <card shadow type="secondary">
                <div slot="header" class="bg-white border-0">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0">product Images</h3>
                        </div>
                    </div>
                </div>
                <div class="productImages">
                    
                    <img @click="showimage(image.image_url)" class="image" v-for="image in return_detail[0].images" :key="image.id" target="_blank" :src="image.image_url">
                </div>
            </card><br/>
        </div>
    </div>
</template>
<script>
import axios from "../axiosCustom" ;

  export default {
   
    data() {
      return {
          rejection_reason:'',
        timeslot:'',
        headers:[
            {name:'Product'},                 
            {name:'Return Reason'},
            {name:'Return Type'},
            {name:'Return Process'},
        ],
        id:'',
        return_detail:[],
        
        openModel:false,
        rejectModel:false

      }
      
    },
    async created(){
        this.id=this.$route.query.id;   
         try{
             let id =this.$route.query.id;
   
           let token = window.$cookies.get("accessToken")
           const response = await axios.get(`/vendor/return-exchange-item-detail/${id}`,{
                headers: {
                'Authorization': `Bearer ${token}`
               }
            })

            if(response.message == 'Success'){
                this.return_detail=response.data
                console.log("this.return_detail",this.return_detail)
          
                
            }
      }
      catch(error){
        console.log(error)
      }
    },
   methods:{
    showimage(url){
    window.open(url, "_blank");
    },
       async action(type){
            try{
                    let params = {
                    rid: this.return_detail[0].id,
                    item_id: this.return_detail[0].item_id,
                    approve_status : type === 'accept' ? 1 : 0,
                  
                    }
                    if(type=='reject'){
                    params.rejection_reason=this.rejection_reason
                    if(this.rejection_reason==''){
                        this.$Msg.error('Please Mention Rejection Reason', { position: 'top-right' ,timeout: 3000 });
                        return true;
                    }
                    }
                    let token = window.$cookies.get("accessToken")
                    const response = await axios.post('/vendor/accept-reject-return-exchange-request',params,{
                        headers: {
                        'Authorization': `Bearer ${token}`
                        }
                    })
                     location.reload();

                if(response.message == 'Success'){
                    this.$Msg.success(response.message, { position: 'top-right' ,timeout: 3000 });
                    this.$router.push('/returns')
                    this.openModel =  false;
                    this.rejectModel=false;
                    this.rejection_reason='',
                    // await this.$store.dispatch('getReturns',this.page)
                    this.total = (this.$store.state.totalReturns)
                    this.data= this.$store.state.returns
                    this.loader = false;
                }

            }
            catch  (error)
            {
                  this.$Msg.error(error.message, { position: 'top-right' ,timeout: 3000 });
                  this.rejectModel=false;
            }
        },
   reject(){
       this.openModel=true
   },
   return_reject(){
       this.rejectModel=true
   },
   async markAsCompleted(type)
   {
    try{
            let params = {
            rid: this.return_detail[0].id,
            item_id: this.return_detail[0].item_id,
            approve_status : type === 'accept' ? 1 : 0,
            'marked_by' : 'admin'
            }
            if(type=='reject'){
            params.rejection_reason=this.rejection_reason
            if(this.rejection_reason==''){
                this.$Msg.error('Please Mention Rejection Reason', { position: 'top-right' ,timeout: 3000 });
                return true;
            }
            }
            let token = window.$cookies.get("accessToken")
            const response = await axios.post('/vendor/accept-reject-return-exchange-request',params,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
                location.reload();

            if(response.message == 'Success'){
                this.$Msg.success(response.message, { position: 'top-right' ,timeout: 3000 });
                this.$router.push('/returns')
                this.openModel =  false;
                this.rejectModel=false;
                this.rejection_reason='',
                // await this.$store.dispatch('getReturns',this.page)
                this.total = (this.$store.state.totalReturns)
                this.data= this.$store.state.returns
                this.loader = false;
            }

            }
            catch  (error)
            {
                  this.$Msg.error(error.message, { position: 'top-right' ,timeout: 3000 });
                  this.rejectModel=false;
            }
   },
   async confirmreject(type){
        try{
            let params = {
            rid: this.return_detail[0].id,
            item_id: this.return_detail[0].item_id,
            approve_status : type === 'accept' ? 1 : 0,
            stores:[this.return_detail[0].store_id],
            pickup_status:type === 'accept' ? 'pickup_confirmed' : 'pickup_rejected',
            }

            if(type=='reject'){
             params.rejection_reason=this.rejection_reason
             if(this.rejection_reason==''){
                        this.$Msg.error('Please Mention Rejection Reason', { position: 'top-right' ,timeout: 3000 });
                        return true;
            }
            }
            let token = window.$cookies.get("accessToken")
            this.loader = true;
            this.openModel=false
            const response = await axios.post('/vendor/confirm-reject-request',params,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            if(response.message == 'Success'){
            console.log(response)
            this.openModel=false
            this.loader = false;
            this.rejection_reason=''
            location.reload();
            
            }

        }
        catch (error)
        {
            console.log(error)
        }
   }
   }

  };
</script>

<style scoped>
.productImages{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.image{
    width:25%;
    margin:2%;
    cursor: pointer;
}
    .table thead th {
        border: 0;
    }
    .container-fluid.align-items-left{
        padding-left: 13px !important
    }
</style>
    

