<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                            <div class="card-header border-0"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                      Commission breakdown for amount {{tr_value}}
                                    </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table tablesorter" :class="tableClass">
                                    <thead :class="theadClasses">
                                        <tr>
                                        <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                        </tr>
                                    </thead>
                                    <tbody :class="tbodyClasses">
                                        <tr> <td>Commission {{data.commission_in_percentage}}</td><td>{{data.commission_in_percentage_value}}</td></tr>
                                        <tr> <td>Commission Fees</td><td>{{data.commission_fees}}</td></tr>
                                        <tr> <td>Listing Fee Per Transaction</td><td>{{data.listing_fee_per_transaction}}</td></tr>
                                        <tr> <td>GST {{data.gst_percentage}}</td><td>{{data.gst_percentage_value}}</td></tr>
                                        <tr> <td>COD Charges</td><td>{{data.cod_charge}}</td></tr>
                                        <tr> <td>Prepaid Charge</td><td>{{data.online_charge}}</td></tr>
                                        <tr> <td><b>Total</b></td><td>{{data.balance}}</td></tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "../axiosCustom" ;
    export default {
        name: 'tables',

        components: {
    },

    data () {
        return {
            data:[],
            type:'',
            tr_value:'',
            headers : [
             { name: '# Type of Charges',value:'type'},
             { name: 'Amount',value:'amount',align:'right'},
            ]
        }
    },
    props: {
        theadClasses: {
            type: String,
            default: '',
            description: '<thead> css classes'
        },
        tbodyClasses: {
            type: String,
            default: '',
            description: '<tbody> css classes'
        },
        align: {
            type: String,
            default: "",
            description: "Pagination alignment (e.g center|start|end)"
        }
  },

    computed: {
        tableClass() {
         return this.type && `table-${this.type}`;
        },
    },
    watch: {
    },
        async mounted(){
            console.log("this.$route.query.id",this.$route.query.id)
            let token = window.$cookies.get("accessToken")
            const response = await axios.get(`/vendor/view-transaction/${this.$route.query.id}`,{
                    headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            console.log("res", response)
            this.data = response;
            this.tr_value = this.data.transaction_value;
    },
    methods: {
    
    }
  };
</script>
<style>
.table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}
.table td, .table th {
    text-align: center;
    
}
a.order{
    color:#2dce89;
    cursor: pointer;
}
</style>
