<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 mr-0 pt-md-8">
      <!-- Card stats -->
      <!-- <div class="row">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Total traffic"
                                type="gradient-red"
                                sub-title="350,897"
                                icon="ni ni-active-40"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Total traffic"
                                type="gradient-orange"
                                sub-title="2,356"
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Sales"
                                type="gradient-green"
                                sub-title="924"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 5.72%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Performance"
                                type="gradient-info"
                                sub-title="49,65%"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
            </div> -->
    </base-header>
     <!--Tables-->
    <div class="row mt--7 mr-0 mb-4 pr-0 col-12">
      <div class="col-xl-6 mb-5 mb-xl-0 pl-4 pr-0">
        <div style="height: 100%;" class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
          <div
            class="card-header border-0"
            :class="type === 'dark' ? 'bg-transparent' : ''"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">Orders</h3>
              </div>
              <div class="col text-right">
                <base-button type="primary" size="sm" @click="allOrder()"
                  >See all</base-button
                >
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table tablesorter table-sm" :class="tableClass">
              <thead :class="theadClasses">
                <tr>
                  <th v-for="item in headers" :key="item.id">
                    {{ item.name }}
                  </th>
                </tr>
              </thead>
              <tbody :class="tbodyClasses">
                <tr v-for="item in data" :key="item.id" class="pr-0 mr-0">
                  <td class="ml-0">
                      <a @click="details(item)" class="order"
                        ><b>#{{ item.order_increment_id }}({{item.order_quote_id}})</b></a
                      >
                    </td>
                    <td v-if="item.accepted_orders_count">{{ item.accepted_orders_count }}</td>
                  <td v-else></td>
                  <td v-if="item.grand_total">₹ {{ numberFormate(item.grand_total)   }}</td>
                  <td v-else></td>
                  
                  <td class="pr-0 mr-0 " v-if="item.time_slot">{{ `${item.time_slot} , ${item.time_slot_date}` || "" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-6 pr-2 pl-4 m-0">
        <div style="height: 100%;" class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
          <div
            class="card-header border-0"
            :class="type === 'dark' ? 'bg-transparent' : ''"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">Request</h3>
              </div>
              <div class="col text-right">
                <base-button type="primary" size="sm" @click="allRequestOrder()"
                  >See all</base-button
                >
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table tablesorter table-sm" :class="tableClass">
              <thead :class="theadClasses">
                <tr>
                  <th v-for="item in headers" :key="item.id">
                    {{ item.name }}
                  </th>
                </tr>
              </thead>
              <tbody :class="tbodyClasses">
                <tr v-for="(item) in requestData" :key="item.id" >
                  <td>
                      <a @click="requestDetails(item)" class="order"
                        ><b>#{{ item.order_increment_id }}({{item.order_quote_id}})</b></a
                      >
                    </td>
                  <td >{{ item.order_items.length||'' }}</td>
                   <td v-if="item.grand_total">₹ {{ numberFormate(item.grand_total) }}</td>
                  <td v-else></td>
                  <td v-if="item.time_slot">{{ item.time_slot|| "" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  <!--End tables-->
    <!--Charts-->
    <div class="container-fluid  mr-0">
      <div class="row mr-0">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Sales value</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <!-- <li class="nav-item mr-2 mr-md-0">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: bigLineChart.activeIndex === 0}"
                                           @click.prevent="initBigChart(0)">
                                            <span class="d-none d-md-block">Month</span>
                                            <span class="d-md-none">M</span>
                                        </a>
                                    </li> -->
                  <li class="nav-item">
                    <a
                      class="nav-link py-2 px-3"
                      href="#"
                      :class="{ active: bigLineChart.activeIndex === 1 }"
                      @click.prevent="initBigChart(0)"
                    >
                      <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">Total orders</h5>
              </div>
            </div>

            <bar-chart :height="350" ref="barChart" :chart-data="redBarChart.chartData">
            </bar-chart>
          </card>
        </div>
      </div>
    </div>
    <!-- End charts-->

   

  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import axios from "../axiosCustom";
// import { componentsPlugin } from 'bootstrap-vue/esm';
// Tables
// import SocialTrafficTable from './Dashboard/SocialTrafficTable';
// import PageVisitsTable from './Dashboard/PageVisitsTable';

export default {
  components: {
    LineChart,
    BarChart,
    // PageVisitsTable,
    // SocialTrafficTable,
  },
  data() {
    return {
      headers: [
        {name:'Id'},
        { name: "Items", value: "status" },
        { name: "Amount", value: "status" },
        { name: "Time slot", value: "time" },
      ],
      bigLineChart: {
        allData: [[]],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ["1", "2", "3", "4", "5", "6", "7"],
          datasets: [
            {
              label: "Order",
              data: [],
            },
          ],
        },
      },
    };
  },
  methods: {
    numberFormate(data){
      return new Intl.NumberFormat("en-IN").format(data)
    },
    allOrder() {
      this.$router.push("orders/all");
    },
     async details(item) {
      this.$router.push({
        name: "Details",
        query: { id: item.order_quote_id },
        params: { item: item },
      });
    },
     async requestDetails(item) {
      console.log("teim", item);
      if (item.is_hopped == true) {
        let id = `${item.order_quote_id}`;
        this.$router.push({
          name: "Order Details",
          query: { id: id, h: true,type:'pending_order' },
          params: { item: item },
        });
      } else {
        this.$router.push({
          name: "Order Details",
          query: { id: item.order_quote_id,type:'pending_order' },
          params: { item: item },
        });
      }
      return;
    },
    allRequestOrder(){
      this.$router.push("orders/requests");
    },
    initBigChart(index) {
      console.log("index", index, this.bigLineChart.allData);
      let chartData = {
        datasets: [
          {
            label: "Performance",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: ["1", "2", "3", "4", "5", "6", "7"],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    callDate() {
      let chartData = {
        datasets: [
          {
            label: "Order",
            data: this.redBarChart.chartData.datasets[0].data,
          },
        ],
        labels: ["1", "2", "3", "4", "5", "6", "7"],
      };
      this.redBarChart.chartData = chartData;
    },
  },
  async mounted() {
    this.dataArr = [];
    this.data=[];
    this.requestData = [];
    await this.$store.dispatch("getOrders",{item: this.page, key:'all'} );
    this.total = this.$store.state.totalOrder;
   
    if(this.$store.state.orders.length>5)
    {
      for(let i=0; i<5 ;i++){
        this.data.push(this.$store.state.orders[i])
      }
    }
    else{
      this.data = this.$store.state.orders;
    }
    

    await this.$store.dispatch("getReqOrder");
    if(this.$store.state.reqOrders.length>5)
    {
      for(let i=0; i<5 ;i++){
        this.requestData.push(this.$store.state.reqOrders[i])
      }
    }
    else{
      this.requestData  = this.$store.state.reqOrders;
    }

    let token = window.$cookies.get("accessToken");
    const res = await axios.get(`/vendor/dashboard`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    res.weekly_amount.map((e) => {
      this.bigLineChart.allData[0].push(e);
    });

    this.redBarChart.chartData.datasets[0].data = res.order_count;
    this.initBigChart(0);
    this.callDate();
  },
};
</script>
<style scoped>
  a.order {
  color: #2dce89;
  cursor: pointer;
}
.table thead td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.85rem !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #e9ecef;
}
.card .table td, .card .table th {
     padding-left: 15px !important;
}
</style>
