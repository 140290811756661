<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <div class="row">
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Current Balance"
                        type="gradient-green"
                        icon="ni ni-money-coins"
                        :sub-title=latestAmount
                        class="mb-4 mb-xl-0">
                    </stats-card>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                            <div class="card-header border-0"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                       Settlements
                                    </h3>
                                    </div>
                                    <div class="col text-right">
                                    <!-- <base-button type="primary" size="sm" @click="openReport()">Report</base-button> -->
                                    </div>
                                </div>
                            </div>
                            <div v-if="openReportModel">
                                <div class="modal" style="display:block; width:100%">
                                <div class="modal-dialog">
                                    <div class="modal-content ml-279 t-200" style="width:590px; ">
                                        <div class="modal-header"><h3 class="modal-title">Export report</h3>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModel()">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body row m-0">
                                          <span class="col-2 p-0">From date</span> <div class="col-4 p-0"> <datepicker class="date-picker" v-model="fromDate" typeable name="uniquename"></datepicker> </div>
                                          <span lass="col-2 p-0">To date</span>  <div class="col-4 p-0 ml-4"> <datepicker class="date-picker" input-class="picker" v-model="toDate" :disabled-dates="disabledDates"  typeable name="uniquename"></datepicker> </div>
                                        </div>
                                         <div class="modal-footer">
                                            <download-excel :data="reportData" :name="filename" :fetch="exportReport(fromDate, toDate)" v-if="fromDate !== '' && toDate !== ''">
                                                <button  type="button" class="btn btn-primary">Export</button>
                                            </download-excel>
                                        </div>
                                    </div>
                                </div>
                             </div>
                            </div>
                      
                            <div class="product_loader" v-show="loader == true"><img src="/img/loader.gif"></div>

                            <div class="table-responsive">
                                <table class="table tablesorter" :class="tableClass">
                                    <thead :class="theadClasses">
                                        <tr>
                                            <th v-for="item in headers" :key="item.id">{{item.name}}</th>
                                        </tr>
                                    </thead>
                                    <tbody :class="tbodyClasses">
                                        <tr v-for="(item) in data" :key="item.id">
                                      
                                        <!-- <td>{{index+1}}</td> -->
                                       <td v-if="item.transaction_id !== null"> <a class="order" @click="details(item)">{{item.transaction_id}}</a></td><td v-else></td>
                                        <td v-if="item.created_at">{{item.created_at}}</td><td v-else></td>
                                        <td v-if="item.transaction_type">{{item.transaction_type}}</td><td v-else></td>
                                        <td v-if="item.payment_type">{{item.payment_type}}</td><td v-else></td>
                                        <td v-if="item.payment_id">{{item.payment_id}}</td><td v-else></td>
                                        <td v-if="item.order_id">{{item.order_id}}</td><td v-else></td>
                                        <td v-if="item.total_transaction_value">₹ {{item.total_transaction_value}}</td><td v-else></td>
                                        <td v-if="item.rate_card_id">{{item.rate_card_id}}</td><td v-else></td>
                                        <td v-if="item.platform_charges">{{item.platform_charges}}</td><td v-else></td>
                                        <td v-if="item.amount_credited_or_debited">{{item.amount_credited_or_debited}}</td><td v-else></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="card-footer d-flex justify-content-end"
                                :class="type === 'dark' ? 'bg-transparent': ''">
                            <!-- <base-pagination total=170></base-pagination> -->
                            <template v-if="data.length>0">
                                <ul class="pagination" :class="[size && `pagination-${size}`, align && `justify-content-${align}`]">
                                    <li class="page-item pointer prev-page" :class="{disabled: value === 1}">
                                    <a class="page-link" aria-label="Previous" @click="prevPage">
                                        <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
                                    </a>
                                    </li>
                                    <li class="page-item pointer" :class="{active: value === item}"
                                        :key="item"
                                        v-for="item in range(minPage, maxPage)">
                                    <a class="page-link " @click="changePage(item)">{{item}}</a>
                                    </li>
                                    <li class="page-item next-page pointer" :class="{disabled: value === totalPages}">
                                    <a class="page-link" aria-label="Next" @click="nextPage">
                                        <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </a>
                                    </li>
                                </ul>
                                </template>
                            </div>

                          </div>
                    <!-- <projects-table title="Products"  :columns=headers :data=orders></projects-table> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//  import $ from 'jquery';
    import axios from "../axiosCustom" ;

    import Datepicker from 'vuejs-datepicker';
    export default {
        name: 'tables',
        components: { Datepicker },
    data () {
        return {
            disabledDates: {
                from: new Date(),
            },
            reportData:[],
            latestAmount:0,
            index:1,
            page:1,
            loader:true,
            fromDate:'',
            toDate:'',
            currentDate: new Date(),
            time1: null,
            time2: null,
            time3: null,
            data:[],
            openReportModel:false,
            // page:1,
            type:'',
            filename:'',
            selected: [],
            selectAll: false,
            defaultPagesToDisplay: 3,
            headers : [
                // { name: 'S.No.',value:'discount',width:'10%'},

                { name:'Transaction Id',value:'store'},
                { name: 'Date',value:'discount'},
                { name:'Transaction Type',value:'status'},
                { name:'Payment Type',value:'status'},
                { name:'Payment Id',value:'status'},
                { name:'Order Id',value:'status'},
                { name:'Total Tran Value',value:'status'},
                { name:'Rate Card ID',value:'status'},
                { name:'Total Platform Charges',value:'status'},
                { name:'Total Credited/Debited',value:'status'},
            ]
        }
    },
    props: {
        theadClasses: {
            type: String,
            default: '',
            description: '<thead> css classes'
        },
        tbodyClasses: {
            type: String,
            default: '',
            description: '<tbody> css classes'
        },
        pageCount: {
            type: Number,
            default: 0,
            description:
            "Pagination page count. This should be specified in combination with perPage"
        },
        perPage: {
            type: Number,
            default: 10,
            description:
            "Pagination per page. Should be specified with total or pageCount"
        },
        total: {
            type: Number,
            default: 0,
            description:
            "Can be specified instead of pageCount. The page count in this case will be total/perPage"
        },
        value: {
         type: Number,
         default: 1,
         description: "Pagination value"
        },
        size: {
         type: String,
         default: "",
         description: "Pagination size"
        },
        align: {
         type: String,
         default: "",
         description: "Pagination alignment (e.g center|start|end)"
        }
  },

    computed: {
        tableClass() {
         return this.type && `table-${this.type}`;
        },
        totalPages() {
            console.log("pageCount",this.pageCount,this.perPage,this.total)
            if (this.pageCount > 0) return this.pageCount;
            if (this.total > 0) {
                console.log("in total");
                return Math.ceil(this.total / this.perPage);

            }
            return 1;
        },
        pagesToDisplay() {
            console.log("totalPages",this.totalPages)
        if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
            
            console.log("this.totalPagesvbnm,./",this.totalPages)
            return this.totalPages;
        }
        return this.defaultPagesToDisplay;
        },
        minPage() {
        if (this.value >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.value;
            if (newMaxPage > this.totalPages) {
            return this.totalPages - this.pagesToDisplay + 1;
            }
            return this.value - pagesToAdd;
        } else {
            return 1;
        }
        },
        maxPage() {
            console.log("max");
        if (this.value >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.value;
            if (newMaxPage < this.totalPages) {
             return newMaxPage;
            } else {
             return this.totalPages;
            }
        } else {
            return this.pagesToDisplay;
        }
        }
    },
    watch: {
        perPage() {
        this.$emit("input", 1);
        },
        total() {
        this.$emit("input", 1);
        }
    },
    async mounted(){
      if(localStorage.getItem("role") == "vendor"){
        window.location.href = "/dashboard";
      } else {
        await this.$store.dispatch('getSettlement', this.page)
        this.total = (this.$store.state.totalSettlement)
        console.log("this.totsl", this.total);
        console.log("this.dxfcgvhjklkjhgf", this.$store.state.Settlement)
        this.data = this.$store.state.Settlement;
        if (this.data.length > 0) {
          this.latestAmount = this.data[0].balance
        }
        console.log("this.data==", this.data)
        this.loader = false;
      }
    },
    
    methods: {
        async exportReport(from, to){
            if(from == ''){return}
            console.log("this.reportData.length",this.reportData.length)
            if(this.reportData.length==0){
             let date = new Date();
            let mlist = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
            let month = date.getMonth();
            let mon =  mlist[month];
            let day = date.getDate();
            let year =  date.getFullYear();
            let min = date.getMinutes();
            let sec= date.getSeconds();
            let hour =  date.getHours();
            let slot = 'AM'
            var time = ''
            if(hour>12){
                 time = hour-12 ;
                slot = 'PM'
            }
            else{
                 time = hour
            }
            var name = [day,mon,year,time,min,sec,slot,'11','settlement_report.xls'].join('_')

            this.filename = name;
            var fromDay = from.getDate();
            let fromMonth = (from.getMonth()+1);
            let fromYear =  from.getFullYear();
            if (fromDay<9){
             fromDay = '0' + fromDay;

            }
            let fromDate = [fromYear, fromMonth, fromDay].join('-');


            let toDay = to.getDate();
            if (toDay < 9) 
             toDay = '0' + toDay;
            let toMonth = (to.getMonth()+1);
            let toYear =  to.getFullYear();
            let toDate = [toYear, toMonth, toDay].join('-');
            let params = {
                start: fromDate,
                end: toDate
            }
            let token = window.$cookies.get("accessToken")
            const response = await axios.post('/vendor/export-transactions',params,{
                headers: {
                'Authorization': `Bearer ${token}`
               }
              })
            this.reportData = response.data;
            }
           
        },
        async details(item){
            this.$router.push({ name: 'Settlement Details', query: { id: item.id }})
        },
        select() {
            this.selected = [];
                if (!this.selectAll) {
                for (let i in this.data) {
                this.selected.push(this.data[i].id_product);
                }
            }
        },
        openReport(){
         this.openReportModel = true;
        },
        closeModel(){
            this.openReportModel =false;
        },
        range(min, max) {
            let arr = [];
            for (let i = min; i <= max; i++) {
                arr.push(i);
            }
            return arr;
        },
        async changePage(item) {
            console.log("ie",item,this.value)
            if(this.value != item){
                this.loader = true;
                this.value = item
                await this.$store.dispatch('getSettlement',item)
                this.data = this.$store.state.Settlement
                this.loader = false;
                this.$emit("input", item);
            }
        },
        async nextPage() {
            console.log("thiss,value",this.value, this.totalPages)
            if (this.value < this.totalPages) {
                console.log("in enter");
                this.value = this.value + 1;
                // await this.changePage(this.value)
                await this.$store.dispatch('getSettlement',this.value)
                this.data = this.$store.state.Settlement
                this.loader = false;
                this.$emit("input", this.value + 1);
            }
            console.log("thiss,value",this.value, this.totalPages)

        },
       async  prevPage() {
        if (this.value > 1) {
            this.$emit("input", this.value - 1);
            this.value = this.value - 1;
            await this.$store.dispatch('getSettlement',this.value)
            this.data = this.$store.state.Settlement
            this.loader = false;
            // await this.changePage(this.value)
        }
        },
       
     }

  };
</script>
<style>
.table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}
.table td, .table th {
    text-align: center;
    
}
a.order{
    color:#2dce89;
    cursor: pointer;
}
.date-picker{
    margin-left: 2px;
}
.date-picker input{
    width: 90% !important;
}
</style>
